import {combineReducers} from 'redux'
import {Login,Attribute,AttributeValues,Category,Product,Customer} from './reducer';

export default combineReducers({
    Login,
    Attribute,
    AttributeValues,
    Category,
    Product,
    Customer
})