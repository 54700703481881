import React, { useState } from 'react';

const initialState = {
    loading: 0
};

export const Context = React.createContext();

export const LoadingContext = ({ children }) => {
    const [loading, setLoading] = useState(0);

    return (
        <Context.Provider value={[loading, setLoading]}>{children}</Context.Provider>
    )
}

export default LoadingContext;