import React from 'react';
import { makeStyles, Card, CardContent, Typography, Divider } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Redirect  } from 'react-router-dom';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        border: 'none',
    },
    cardClass: {
        padding: theme.spacing(3),
    },
    cardContent: {
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: '0 !important',
    },
    divider: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    spacer: {
        marginBottom: theme.spacing(3),
    },
    breadcrumbTitle: {
        fontWeight: 500,
        marginTop: theme.spacing(1),
    },
}));



export default function Breadcrumbs (props) {
    const classes = useStyles();
    const { color, outline, size, title, divider, isCard, ...rest } = props;
    let cardClass = classes.root;
    if (isCard) {
        cardClass = classes.cardClass;
    }

    const onAddNew = () =>{
        props.onAddNew();
    }

    return (
        <Card className={cardClass}>
            
                 
            <CardContent className={classes.cardContent} style={{ padding : '0px' }}>
            <div className='d-flex'>
                <Link to="/dashboard/default" style={{ textDecoration: 'none', color: 'var(--bs-gray-dark)' }}>
                    <p className='mb-0' style={{fontSize: 'small'}}>&nbsp;Home</p>
                </Link>
                &nbsp;/
                <p className='mb-0' style={{ color: '#3366ff',fontSize: 'small' }}>&nbsp;{title}</p>
            </div>
                {/* <MuiBreadcrumbs {...rest} /> */}
                {/* {title && (
                    <Typography className={classes.breadcrumbTitle} variant="h3">
                        {title}{props.isadd ? <Button variant="contained" style={{ float: 'right' }} onClick={onAddNew}><span className='SetFont'>Add New</span></Button> :''}
                    </Typography>

                )} */}
                {title && (
                    <div className="row"> {/* Add the row class here */}
                        <div className='col-12 col-md-6'>
                            <Typography className={classes.breadcrumbTitle} variant="h3">
                                {title}
                            </Typography>
                        </div>
                        <div className='col-12 col-md-6'>

                            {props.isadd && (
                                <Button
                                    variant="contained"
                                    style={{ float: 'right' }}
                                    onClick={onAddNew}
                                >
                                    <span className='SetFont'>Add New</span>
                                </Button>
                            )}
                        </div>

                    </div>
                )}


                {divider === false && !isCard && <div className={classes.spacer} />}
                {divider !== false && <Divider className={classes.divider} />}
            </CardContent>
        </Card>
    );
};
