import {LOGIN,LOGOUT,ATTRIBUTE, ATTRIBUTE_VALUES, CATEGORY, PRODUCT, CUSTOMER} from '../constants';

export const Login = (state1 = {},action1) => {
   
    switch(action1.type){
        case LOGIN:
            return action1.data1
            // return [
            //     ...state1,
            //     action1.data1
            // ]
        case LOGOUT:
            
            return {}
        default:
            return state1;
    }
}

export const Attribute = (state = [],action) => {
   
    switch(action.type){
        case ATTRIBUTE:
            return action.data;
        case LOGOUT:
            return []
        default:
            return state;
    }
}

export const AttributeValues = (state = [],action) => {
   
    switch(action.type){
        case ATTRIBUTE_VALUES:
            return action.data;
        case LOGOUT:
            return []
        default:
            return state;
    }
}

export const Category = (state = [],action) => {
   
    switch(action.type){
        case CATEGORY:
            return action.data;
        case LOGOUT:
            return []
        default:
            return state;
    }
}

export const Product = (state = [],action) => {
   
    switch(action.type){
        case PRODUCT:
            return action.data;
        case LOGOUT:
            return []
        default:
            return state;
    }
}

export const Customer = (state = [],action) => {
   
    switch(action.type){
        case CUSTOMER:
            
            return action.data;
        case LOGOUT:
            return []
        default:
            return state;
    }
}
