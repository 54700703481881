

import React, { useState, useContext } from 'react';
import Breadcrumb from '../../component/Breadcrumb/Breadcrumbs';
import {  useHistory } from 'react-router-dom';
import {
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,

} from '@material-ui/core';
import Button from '@mui/material/Button';

import { FormattedMessage } from 'react-intl';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import './ChangePassword.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { environment } from "../../environment";
import { toast } from 'react-toastify';
import { Context } from "../../views/Loding/LoadingContext";
export default function ChangePassword() {
    const [passwordOld, setPasswordOld] = useState("");
    const [password, setPass] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = React.useState(false);
    const [emailerror, setemailError] = React.useState(false);
    const [isCustomer, setcustomner] = React.useState(false);
    const [passwordToggle, setpasswordToggle] = useState(true);
    const [passwordToggleOld, setpasswordToggleOld] = useState(true);
    const [passwordToggleconfirm, setpasswordToggleconfirm] = useState(true);
    const [loading, setLoading] = useContext(Context);
    const history = useHistory();




    const passwordIconToggle = async () => {
        setpasswordToggle(!passwordToggle);
    }
    const passwordIconTogglesOld = async () => {
        setpasswordToggleOld(!passwordToggleOld);
    }
    const passwordIconTogglesconfirm = async () => {
        setpasswordToggleconfirm(!passwordToggleconfirm);
    }
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [ConfirmPasswordError, setConfirmPasswordError] = useState("");


    const onChangePasswordOld = (e) => {
        const value = e.target.value;
        setPasswordOld(value);
        if (!value) {
            setOldPasswordError("Old Password is required");
        } else {
            setOldPasswordError("");
        }
    };

    const onChangePassword = (e) => {
        const value = e.target.value;
        setPass(value);
        if (!value) {
            setNewPasswordError("New Password is required");
        } else {
            setNewPasswordError("");
        }
    };
    // const onConfirmPasswordChange = (e) => {
    //     const value = e.target.value;
    //     setConfirmPassword(value);
    //     if (!value) {
    //         setConfirmPasswordError("Confirm Password is required");
    //     } else {
    //         setConfirmPasswordError("");
    //     }
    // };
    const onConfirmPasswordChange = (e) => {
        const newPassword = password;
        const confirmPasswordValue = e.target.value;

        // Check if newPassword and confirmPassword match
        if (newPassword !== confirmPasswordValue) {
            setConfirmPasswordError("Passwords do not match");
        } else {
            setConfirmPasswordError("");
        }


        setConfirmPassword(confirmPasswordValue);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (localStorage.getItem('authUser')) {
            const auth = JSON.parse(localStorage.getItem('authUser'));
            setOldPasswordError("");
            setNewPasswordError("");
            setConfirmPasswordError("");

            if (!passwordOld) {
                setOldPasswordError("Old Password is required");
            }

            if (!password) {
                setNewPasswordError("New Password is required");
            }

            if (!confirmPassword) {
                setConfirmPasswordError("Confirm Password is required");
            }


            if (!passwordOld || !password || !confirmPassword) {
                return;
            }
            setLoading(1)

            try {
                const result = await ChangePassword(auth);
                if (result.success) {

                    setPasswordOld("");
                    setPass("");
                    setConfirmPassword("");
                    setLoading(0)
                    toast.success("Change Password Successfully", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'SetFont'
                    });
                } else {
                    setLoading(0)
                    toast.error(result.responseMessage, {
                        position: "top-right",
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        className: 'SetFont'
                    });
                    if(result.message.includes("Failed to parse URL")){
                        redirect();
                    }
                }
            } catch (error) {
                console.error(error);
                setLoading(0)
                toast.error("An error occurred while Change Password.", {
                    position: "top-right",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    className: 'SetFont'
                });
                if(error.message.includes("Failed to parse URL")){
                    redirect();
                }
            }
        }
    };




    const ChangePassword = async (auth) => {
        try {
        const passwordData = {
            oldPassword: passwordOld,
            newPassword: password,
        };
        const result = await fetch(environment.SERVER_URL + '/changePassword', {
            method: 'post',
            body: JSON.stringify(passwordData),
            headers: {
                "Authorization": `OEC ${auth.token}`,
                "Content-Type": "application/json"
            },
        });
    
        const resultData = await result.json();
        return resultData;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if(error.message.includes("Failed to parse URL")){
                redirect();
            }
            return error; // Rethrow the error to handle it in the calling code
        }
    }

    const redirect = () => {
        history.push("/login");
      }

    return (

        <div>
            <React.Fragment>
                <Breadcrumb title={' Change Password'} >
                    {/* <Typography to="/dashboard/default" variant="subtitle2" color="inherit" className="link-breadcrumb">
                        <FormattedMessage id="home" />
                    </Typography>
                    <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
                        Change Password
                    </Typography> */}
                </Breadcrumb>

                <Card>
                    <CardContent>
                        <div className="profile-container">

                            <div className="profile-field">
                                <div className='row'>
                                    <div className='col-8 col-md-9'>
                                        <label for="email" className="profile-label">Old Password:</label>
                                    </div>
                                    <div className='col-4 col-md-3 text-center' style={{ marginTop: '28px' }}>
                                        {
                                            passwordToggleOld ?
                                                <><FontAwesomeIcon icon={faEye} className='IconInChangepassword' onClick={passwordIconTogglesOld} /></>
                                                :
                                                <><FontAwesomeIcon icon={faEyeSlash} className='IconInChangepassword' onClick={passwordIconTogglesOld} /></>
                                        }
                                    </div>
                                </div>
                                <input type={passwordToggleOld ? 'password' : 'text'} value={passwordOld} onChange={(e) => { setPasswordOld(e.target.value); onChangePasswordOld(e) }}
                                    placeholder='Enter Old Password' className="form-control custom-input"></input>
                                {oldPasswordError && <div className="error-label">{oldPasswordError}</div>}
                            </div>

                            <div className="profile-field">
                                <div className='row'>
                                    <div className='col-8 col-md-9'>
                                        <label for="email" className="profile-label">New Password:</label>
                                    </div>
                                    <div className='col-4 col-md-3 text-center' style={{ marginTop: '28px' }}>
                                        {
                                            passwordToggle ?
                                                <><FontAwesomeIcon icon={faEye} className='IconInChangepassword' onClick={passwordIconToggle} /></>
                                                :
                                                <><FontAwesomeIcon icon={faEyeSlash} className='IconInChangepassword' onClick={passwordIconToggle} /></>
                                        }
                                    </div>
                                </div>
                                <input type={passwordToggle ? 'password' : 'text'} value={password} onChange={(e) => { setPass(e.target.value); onChangePassword(e) }}
                                    placeholder='Enter New Password' className="form-control custom-input"></input>
                                {newPasswordError && <div className="error-label">{newPasswordError}</div>}
                            </div>
                            <div className="profile-field">
                            <div className='row'>
                                    <div className='col-8 col-md-9'>
                                    <label for="email" className="profile-label">Confirm Password:</label>
                                    </div>
                                    <div className='col-4 col-md-3 text-center' style={{ marginTop: '28px' }}>
                                    {
                                    passwordToggleconfirm ?
                                        <><FontAwesomeIcon className='IconInChangepassword' icon={faEye} onClick={passwordIconTogglesconfirm} /></>
                                        :
                                        <><FontAwesomeIcon className='IconInChangepassword' icon={faEyeSlash} onClick={passwordIconTogglesconfirm} /></>
                                }
                                    </div>
                                </div>
                                <input type={passwordToggleconfirm ? 'password' : 'text'} value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); onConfirmPasswordChange(e) }}
                                    placeholder='Enter Confirm  Password' className="form-control custom-input"></input>
                                {ConfirmPasswordError && <div className="error-label">{ConfirmPasswordError}</div>}
                            </div>

                            <div className='text-center'>
                                <Button variant="contained" className='float-left' onClick={handleSubmit}>
                                    <span className='SetFont'>Change Password</span>
                                </Button>
                            </div>
                     
                        </div>
                    </CardContent>
                </Card>



            </React.Fragment>
        </div>


    );
}
