import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import GroupIcon from '@mui/icons-material/Group';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InterestsIcon from '@mui/icons-material/Interests';
import GradingIcon from '@mui/icons-material/Grading';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
const icons = {
    NavigationOutlinedIcon: NavigationOutlinedIcon,
    FormatListBulletedSharpIcon: FormatListBulletedSharpIcon,
    InterestsIcon: InterestsIcon,
    ProductionQuantityLimitsIcon: ProductionQuantityLimitsIcon,
    ReceiptLongIcon: ReceiptLongIcon,
    LocalActivityIcon: LocalActivityIcon,
    DashboardCustomizeIcon:DashboardCustomizeIcon,
    HomeOutlinedIcon: HomeOutlinedIcon,
    GroupIcon: GroupIcon,
    GradingIcon: GradingIcon,
    ShoppingCartSharpIcon: ShoppingCartSharpIcon,

};

export default {
    items: [
        {
            id: 'navigation',

            type: 'group',
            icon: icons['NavigationOutlinedIcon'],
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    icon: icons['HomeOutlinedIcon'],
                    url: '/dashboard/default',
                },
                {
                    id: 'attributes',
                    title: 'Attributes',
                    type: 'item',
                    icon: icons['InterestsIcon'],
                    url: '/attributes',
                },
                {
                    id: 'attributeValues',
                    title: 'Attribute Values',
                    type: 'item',
                    icon: icons['GradingIcon'],
                    url: '/attributeValues',
                },
                {
                    id: 'category',
                    title: 'Category',
                    type: 'item',
                    icon: icons['DashboardCustomizeIcon'],
                    url: '/category',
                },
                {
                    id: 'product',
                    title: 'Product',
                    type: 'item',
                    icon: icons['ProductionQuantityLimitsIcon'],
                    url: '/product/list',
                },
                {
                    id: 'orders',
                    title: 'Orders',
                    type: 'item',
                    icon: icons['ReceiptLongIcon'],
                    url: '/orders/list',
                },
                {
                    id: 'customer',
                    title: 'Customer',
                    type: 'item',
                    icon: icons['GroupIcon'],
                    url: '/customer/list',
                },
                {
                    id: 'review',
                    title: 'Review',
                    type: 'item',
                    icon: icons['LocalActivityIcon'],
                    url: '/review/list',
                },
            
            ],
        },
    ],
};
