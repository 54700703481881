import React from 'react';
import Breadcrumb from '../../component/Breadcrumb/Breadcrumbs';
import logo from '../../assets/images/user.png';
import {
    Card,
    CardContent,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import './Profile.css';

export default function Profile() {
    const User = JSON.parse(localStorage.getItem('authUser'));

    return (
        <div>
            <React.Fragment>
                <Breadcrumb title={'Profile'}>
                    {/* <Typography to="/dashboard/default" variant="subtitle2" color="inherit" className="link-breadcrumb">
                        <FormattedMessage id="home" />
                    </Typography>
                    <Typography variant="subtitle2" color="primary" className="link-breadcrumb">
                        Profile
                    </Typography> */}
                </Breadcrumb>

                <div className="profile-containers">
                    <Card>
                        <div className="profile-content">
                            <div className="logo-container">
                                <img src={logo} alt="Logo" width={'230px'} />
                            </div>
                            <div className="info-container" style={{ paddingLeft : '10px'}}>
                                <p><strong>Name:</strong> Admin</p>
                                <p><strong>Email:</strong> {User.email}</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </React.Fragment>
        </div>
    );
}
