import React from 'react';
import { Container } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import { ThreeDots } from 'react-loader-spinner';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Loader = () => {
    const classes = useStyles();

    return (

        <div className="loader">
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>


        // <div className={classes.root}>
        //     <LinearProgress color="primary" />
        // </div>
    );
}

export default Loader
