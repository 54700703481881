import React, { useState, useContext } from "react";

import { Modal, Button } from "react-bootstrap";
import { Grid } from '@material-ui/core';
import { environment } from "../../environment";
import { toast } from 'react-toastify';
import { Context } from "../../views/Loding/LoadingContext";
import { ThreeDots } from 'react-loader-spinner';
import './ChangePassword.css'
import {  useHistory } from 'react-router-dom';

function ForgotPassword(props) {
    const [email, setEmail] = useState("");
    const [error, setError] = React.useState(false);
    const [emailerror, setemailError] = React.useState(false);
    const [isForCustomer, setisForCustomer] = React.useState(true);
    const [loading, setLoading] = useContext(Context);
    const history = useHistory();

    function isValidEmail() {
        return /\S+@\S+\.\S+/.test(email);
    }

    const onChangeEmail = (e) => {
        if (!e.target.value) {
            setError(true);
            return false;
        } else if (!e.target.validity.valid) {
            setemailError(true);
        } else {
            //setError(false);
            setemailError(false);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email) {
            setError(true);
            return false;
        } else {

            if (!isValidEmail(email)) {
                setemailError(true);
                return false
            } else {

                try {
                    setError(null);
                    setemailError(false);
                    const formdata = new FormData();
                    formdata.append('email', email);
                    formdata.append('isForCustomer', isForCustomer);
                    setLoading(true);
                    let result = await fetch(environment.SERVER_URL + '/customer/sendmail', {
                        method: 'post',
                        body: formdata,

                    });
                    result = await result.json();

                    if (result.success) {

                        setLoading(false);
                        props.sendMail(email);
                    } else {
                        setLoading(false);
                        toast.error(result.responseMessage, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",

                        });
                        if(result.message.includes("Failed to parse URL")){
                            redirect();
                        }
                    }

                } catch (error) {
                    setLoading(false);
                    toast.error("something is wrong", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    if(error.message.includes("Failed to parse URL")){
                        redirect();
                    }
                }

            }

        }
    }

    const redirect = () => {
        history.push("/login");
      }


    return (
        <div>
            {loading ? (
                <div className="loader-overlay">
                    <ThreeDots
                        height={80}
                        width={80}
                        radius={9}
                        color="#FFFFFF"
                        backgroundColor="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                        style={{ alignItems: 'center' }}
                    />
                </div>
            ) : null}
            <Modal.Body style={{ fontWeight: 'bold', fontSize: '12px' }}>


                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                    <span className='setFontFamily'>Email Address</span>
                    <input type='text' value={email} onChange={(e) => { setEmail(e.target.value); onChangeEmail(e) }}
                        placeholder='Enter Email Address' className='form-control'></input>
                    {error && !email && <><label className="error-label">Email Address is required</label><br></br><br></br></>}
                    {emailerror && email && <><label className="error-label SetFont">Email Address is invalid</label><br></br><br></br></>}

                </Grid>
            </Modal.Body>
            <Modal.Footer>

                {/* <button
                    className="btn btn-dark btn-block btn-rounded bg-primary text-white w-80 border border-white rounded ResetButtone"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                        height: '45px',
                     
                        fontSize: '13px',
                        marginRight: '390px',
                    }}
                >
                    SENT OTP
                </button> */}
                <buttone className=" btn  btn-rounded text-white bg-primary OtpButton" onClick={handleSubmit}>
                    SENT OTP
                </buttone>


            </Modal.Footer>

        </div>
    )
}

export default ForgotPassword
