import React, { lazy, Suspense, useState } from 'react';
import { Switch, Route, useLocation, Navigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Loader from './component/Loader/Loader';
import NavMotion from './layout/NavMotion';
import { ToastContainer } from 'react-toastify';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import { Redirect } from 'react-router-dom';
import Profile from './views/Profile/Profile';
import ChangePassword from '../src/views/Authentication/ChangePassword'
import ForgotPassword from './views/Authentication/ForgotPassword';
import VerifyEmail from './views/Authentication/VerifyEmail';
import NewPassword from './views/Authentication/NewPassword';

const PrivateComponent = lazy(() => import('./views/PrivateComponent'));
const DashboardDefault = lazy(() => import('./Container/Dashboard/DashboardContainer'));
const Category = lazy(() => import('./Container/Category/CategoryListContainer'));
const Attributes = lazy(() => import('./Container/Attributes/AttributeListContainer'));
const AttributeValues = lazy(() => import('./Container/AttributeValues/AttrValuesListContainer'));
const ProductList = lazy(() => import('./Container/Product/ProductListContainer'));
const ProductAddUpdate = lazy(() => import('./Container/Product/ProductAddUpdateContainer'));
const OrderList = lazy(() => import('./Container/Orders/OrderListContainer'));
const OrdersAddUpdate = lazy(() => import('./views/Orders/ordersAddUpdate'));
const CustomerList = lazy(() => import('./Container/Customer/CustomerListContainer'));
const CustomerAddUpdate = lazy(() => import('./Container/Customer/CustomerAddUpdateContainer'));
const ReviewList = lazy(() => import('./Container/Review/ReviewListContainer'));
const Login = lazy(() => import('./Container/LoginContainer'));

const Routes = () => {
    const location = useLocation();
    let auth = null;
    if (localStorage.getItem('authUser')) {
        auth = JSON.parse(localStorage.getItem('authUser'));
    }


    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
              <Switch>
                <Redirect exact from="/" to="/login" />
                <Route path={['/login']}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <NavMotion>
                    <Route path="/login" component={Login} key="login-route" />
                  </NavMotion>
                </Switch>
              </MinimalLayout>
            </Route>
            {auth ? (
              <Route
                path={[
                  '/dashboard/default',
                  '/category',
                  '/attributes',
                  '/attributeValues',
                  '/product/list',
                  '/product/addUpdate',
                  '/product/addUpdate/:id',
                  '/orders/list',
                  '/orders/addUpdate/:id',
                  '/customer/list',
                  '/profile',
                  '/changePassword',
                  '/customer/addUpdate/:id',
                  '/review/list',
                ]}
              >
                <MainLayout>
                  <Switch location={location} key={location.pathname}>
                    <NavMotion>
                      <Route element={<PrivateComponent />} key="private-component">
                        {/* Dashboard */}
                        <Route path="/dashboard/default" component={DashboardDefault} key="dashboard-default" />
                        {/* Category */}
                        <Route path="/category" component={Category} key="category" />
                        {/* Attributes */}
                        <Route path="/attributes" component={Attributes} key="attributes" />
                        {/* Profile */}
                        <Route path="/profile" component={Profile} key="profile" />
                        {/* Change Password */}
                        <Route path="/changePassword" component={ChangePassword} key="change-password" />
                        {/* Attribute Values */}
                        <Route path="/attributeValues" component={AttributeValues} key="attribute-values" />
                        {/* Product List */}
                        <Route path="/product/list" component={ProductList} key="product-list" />
                        {/* Product Add/Update */}
                        <Route path="/product/addUpdate" exact component={ProductAddUpdate} key="product-add-update" />
                        <Route path="/product/addUpdate/:id" component={ProductAddUpdate} key="product-add-update-id" />
                        {/* Orders List */}
                        <Route path="/orders/list" component={OrderList} key="orders-list" />
                        {/* Orders Add/Update */}
                        <Route path="/orders/addUpdate/:id" exact component={OrdersAddUpdate} key="orders-add-update" />
                        {/* Customer List */}
                        <Route path="/customer/list" component={CustomerList} key="customer-list" />
                        {/* Customer Add/Update */}
                        <Route path="/customer/addUpdate/:id" component={CustomerAddUpdate} key="customer-add-update" />
                        {/* Review List */}
                        <Route path="/review/list" component={ReviewList} key="review-list" />
                      </Route>
                    </NavMotion>
                  </Switch>
                </MainLayout>
              </Route>
            ) : (
              <Redirect to="/" key="redirect-to-login" />
            )}
             <Route path="*">
                <Redirect to="/login" />
              </Route>
          </Switch>
        </Suspense>
        <ToastContainer />
      </AnimatePresence>
    );
};

export default Routes;