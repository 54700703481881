import React, { useContext, useEffect } from 'react';
import { Box, Grid, makeStyles, IconButton, Hidden } from '@material-ui/core';

import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';

import SearchSection from './SearchSection';
import Customization from './Customization';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { Link } from 'react-router-dom';

import logo from './../../../assets/images/logo.svg';
import Flogo from './../../../assets/images/favicon-32x32.png'
import { drawerWidth } from './../../../store/constant';
import { Context } from "../../../views/Loding/LoadingContext";
import Loader from '../../../views/Loding/Loader'
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
}));


const Header = (props) => {
    const { drawerToggle } = props;
    const classes = useStyles();
    const [loading, setLoading] = useContext(Context);
    return (
        <React.Fragment>
            {loading == 1 ? <><Loader></Loader></> : null}
            <Box width={drawerWidth}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Link to="/" className="logo" style={{ textDecoration:'auto', color: 'white' }}>
                        <Hidden smDown>
                            <Grid item>
                                <Box mt={0.5}>
                                    <img src={Flogo} alt="Logo" height={'60px'} />
                                    <span style={{ marginLeft: '5px', fontSize: '20px' }}> MG Store </span>
                                </Box>
                            </Grid>
                        </Hidden>
                    </Link>
                    <Grid item>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={drawerToggle}
                        >
                            <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            <div className={classes.grow} />

            {/* <SearchSection theme="light" /> */}
            <Customization />
            <NotificationSection />
            <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
