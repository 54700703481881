import {LOGIN,LOGOUT,ATTRIBUTE,ATTRIBUTE_VALUES,CATEGORY, PRODUCT, CUSTOMER} from '../constants';

export const login = (data1) =>{
    return {
        type:LOGIN,
        data1
    }
} 

export const logout = () =>{

    
    return {
        type:LOGOUT
    }
}

export const attribute = (data) =>{
    return {
        type:ATTRIBUTE,
        data
    }
}

export const attributevalues = (data) =>{
    return {
        type:ATTRIBUTE_VALUES,
        data
    }
}

export const category = (data) =>{
    return {
        type:CATEGORY,
        data
    }
}

export const product = (data) =>{
    return {
        type:PRODUCT,
        data
    }
}

export const customer = (data) =>{
    return {
        type:CUSTOMER,
        data
    }
}