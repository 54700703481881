import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';

import App from './layout/App';
import reducer from './store/Reducers/index';
import config from './config';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/style.scss';
import * as serviceWorker from './serviceWorker';
import { registerLicense } from '@syncfusion/ej2-base';


registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpHaV1DQmFJfFBmRGJTfFh6d1dWACFaRnZdQV1hSHdRdUZkWH1Xc3dW;Mgo+DSMBPh8sVXJ0S0J+XE9AflRBQmFIYVF2R2BJfl96d1NMY15BJAtUQF1hSn5Rd0xhWnxZcXZcQmZf;ORg4AjUWIQA/Gnt2VVhkQlFacldJXnxLf0x0RWFab19xflZAal9TVBYiSV9jS31TdERqW3xdd3VXT2ZcUg==;MTE2MTAzMkAzMjMwMmUzNDJlMzBsWW5VNnd0a2F0MnF6OGlQWnJIY0gzNy8rekVvSTlleTdKd3lTZ2RQZWJvPQ==;MTE2MTAzM0AzMjMwMmUzNDJlMzBGeEFHYlZhN3hhM3ZRTGwyK2FPWXVvSW45NVdoQW1oVkFvQTJOYStKSE9RPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmBWfFJpR2NbfE5xdV9FZlZSRmY/P1ZhSXxQdkdiUH1dc3NURWBfUEc=;MTE2MTAzNUAzMjMwMmUzNDJlMzBQUnp3UjhIbVZlbnEzMEpWK212K0Q3YmxPYVBWQ3ArL0tQSnBXUHdqMFZBPQ==;MTE2MTAzNkAzMjMwMmUzNDJlMzBiWlFxSjNtc0dCQjZ2UE9ZZUdRdENYYnVEcXAvemhzdEptZUdKOGIxci9nPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXnxLf0x0RWFab19xflZAal9TVBYiSV9jS31TdERqW3xdd3VWRmhUVw==;MTE2MTAzOEAzMjMwMmUzNDJlMzBHeWRJVmNTVkxkUkFXYUh3a3NsWUU5UVJydHhRWlJDakM2MWtsa1VHU2dFPQ==;MTE2MTAzOUAzMjMwMmUzNDJlMzBUbi9WMEFPWE1zSDk1MFFIMW5XZk8xcVM0eEFGbENQT0hOTzBQNGRJb2dNPQ==;MTE2MTA0MEAzMjMwMmUzNDJlMzBQUnp3UjhIbVZlbnEzMEpWK212K0Q3YmxPYVBWQ3ArL0tQSnBXUHdqMFZBPQ==')

const store = createStore(reducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
